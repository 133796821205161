import Toplar from "../../images/toplar29.jpg";
import ParkLoka from "../../images/parkLoka3.jpg";

export const HomeItems = [
  {
    image: Toplar,
    titleKey: "home.events.title",
    title2Key: "home.events.subtitle",
    bodyKey: "home.events.description",
    buttonKey: "home.events.button",
    url: "/meni",
  },
  {
    image: ParkLoka,
    titleKey: "home.park.title",
    title2Key: "home.park.subtitle",
    bodyKey: "home.park.description",
    buttonKey: "home.park.button",
    url: "/loka",
  },
];
